.rdt_TableHeadRow,
.rdt_TableCell {
    font-size: 1rem;
}

.rdt_TableCell {
    word-break: break-all;
}
.rdt_TableHeadRow {
    background-color: #5e5e5e !important;
    color: white;
    font-weight: bold;
}

/* .TUEFO {
    padding-top: .5rem!important;
    height: auto!important;
    margin-bottom: 0.5rem;
} */