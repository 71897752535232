.form-control-group-input {
  position: relative;
}

.form-control-type-toggle {
  position: absolute;
  top: .3rem;
  right: .5rem;
}

.form-control-type-toggle label {
  padding-left: .25rem;
  padding-right: .25rem;
  top: .25rem;
  margin: 0;
  text-align: center;
}

.form-control-type-toggle input {
  width: 0;
}

.form-control-type-toggle~.form-control {
  padding-right: 2.25rem;
}

.rdw-editor-wrapper {
  /* height: 100%; */
  margin-bottom: .25rem;
}

.rdw-editor-toolbar {
  border: 1px solid #cdcdcd !important;
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.rdw-editor-main {
  border: 1px solid #cdcdcd;
  padding: 1rem;
  /* background-color: #fafafa; */
  border-radius: 0 0 0.25rem 0.25rem !important;
}

.form-label.hilight {

  display: inline-block;
  padding: 0 .5rem;
  border-radius: .25rem;
  background-color: black;
}

.form-label.hilight span {
  display: inline-block;
  color: #faf0a0;
  background-image: repeating-linear-gradient(to right, #a2682a 0%, #be8c3c 8%, #be8c3c 18%, #d3b15f 27%,
      #faf0a0 35%, #ffffc2 40%, #faf0a0 50%, #d3b15f 75%,
      #b17b32 80%, #bb8332 83%, #d4a245 88%, #e1b453 93%, #a4692a 100%);
  background-size: 210%;
  background-position: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}