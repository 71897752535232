@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@300;400;500;700&display=swap');

body {
  font-family: 'Noto Sans Lao','Noto Sans Thai', sans-serif;
}

.swal-sm {
  width: 75%!important;
  max-width: 540px!important;
}

.swal-lg {
  width: 75%!important;
  max-width: 768px!important;
}

.swal-lg {
  width: 75%!important;
  max-width: 992px!important;
}

.swal-xl {
  width: 75%!important;
  max-width: 1200px!important;
}