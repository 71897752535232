body {
    /* height: 100vh; */
}

#root,
#app {
    height: 100vh;
}

#app {
    display: flex;
    position: relative;
}

#app #main {
    flex-grow: 1;
    overflow-y: auto;
}

#app #main > header {
    height: 60px;
    background-color: #f5f5f5;
    display: flex;
    border-bottom: 1px solid rgba(173, 173, 173, 0.1);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

#app header nav {
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
}

#app header nav .navbar-brand img {
    height: 40px;
}

#app footer {
    margin-top: auto;
}

.pro-sidebar > .pro-sidebar-inner {

}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper,
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 1rem;
}

.pro-sidebar .pro-menu a {
    text-decoration: none;
    color: #eaeaea;
}

.pro-sidebar .header {
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.pro-sidebar .pro-menu-item a.active {
    color: #fff;
}

.pro-sidebar .pro-menu .pro-inner-list-item a.active:after {
    content: '';
    position: absolute;
    top: 1.1rem;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
}

.main-sidebar-toggle {
    width: 80px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .main-sidebar-toggle {
        display: none;
    }
}

.main-sidebar-toggle button {
    border: 0;
    background-color: transparent;
    padding: 10px 15px;
}

.page-form-logo {
    width: 200px;
    margin: 0 auto 30px auto;
    display: block;
}