.card-counter {
    text-align: right;
    position: relative;
    color: #ffffff;
    border-radius: .25rem;
    box-shadow: 0 2px 4px rgb(190, 190, 190);
    padding: 1rem;
}
.card-counter .label {
    opacity: .85;
}
.card-counter .icon {
    opacity: .15;
    font-size: 5rem;
    position: absolute;
    left: -1rem;
    bottom: -1rem;
}